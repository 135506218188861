import React, { FC, ReactNode } from "react";
import SideBar from "./Sidebar";

interface LayoutProps {
  children?: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <div className="layout overflow-x-auto overflow-y-hidden w-screen h-screen flex">
      <SideBar />
      <div className="flex-1 relative">
        <div className="w-full h-full min-w-[1200px] absolute flex-col ctn p-10 overflow-x-hidden overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
