import { notification } from "antd";
import axios, { AxiosResponse, Method, ResponseType } from "axios";
import { setRecoil } from "recoil-nexus";
// import jwtDecode from "jwt-decode";
import { StorageConstant } from "../storage";
import UserAtom from "../user/user.atom";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 15000,
  validateStatus: (status: number) => status < 400,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem(StorageConstant.ACCESS_TOKEN);
  if (token) {
    // const tokenData = jwtDecode(token);

    return {
      ...config,
      headers: {
        ...config.headers,
        authorization: `Bearer ${token}`,
      },
    };
  } else {
    return {
      ...config,
    };
  }
});

const Api = async <T>({
  url,
  method = "POST",
  data,
  params,
  responseType,
  headers,
}: {
  url: string;
  method?: Method;
  data?: any;
  params?: any;
  responseType?: ResponseType;
  headers?: any;
}): Promise<T> => {
  return instance
    .request({
      method,
      url,
      data,
      params,
      responseType,
      headers,
    })
    .then((resp: AxiosResponse<any>) => {
      return resp.data;
    })
    .catch((error) => {
      const errorResponse = (error?.response?.data?.data ||
        error?.response?.data) as IError;
      if (
        errorResponse?.statusCode === 401 ||
        errorResponse?.statusCode === 403
      ) {
        setRecoil(UserAtom.currentUser, undefined);
        localStorage.removeItem(StorageConstant.ACCESS_TOKEN);
        notification.error({
          message: `Lỗi ${errorResponse?.statusCode}!`,
          description: errorResponse?.message,
        });
        return;
      }
      notification.error({
        message: `Lỗi ${errorResponse?.statusCode}!`,
        description: errorResponse?.message?.[0],
      });
      throw new Error(errorResponse?.message?.[0]);
    });
};

export default Api;
