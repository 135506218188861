import { FC } from "react";
import IcoMoon, { IconProps } from "react-icomoon";
const iconSet = require("./selection.json");

const IconSet: FC<IconProps> = (props) => (
  <IcoMoon iconSet={iconSet} {...props} />
);

const Icon: FC<IconProps> = ({ size = 24, color = "#94A3B8", ...rest }) => {
  return <IconSet size={size} color={color} {...rest} />;
};

export default Icon;
