import { Avatar, notification, Popover } from "antd";
import { FC } from "react";
import { useRecoilState } from "recoil";
import UserAtom from "../services/user/user.atom";
import avtNone from "../assets/avatar.svg";
import Icon from "./Icon";
import { useNavigate } from "react-router-dom";
import { StorageConstant } from "../services/storage";

const MiniProfile: FC = () => {
  const [user, setUser] = useRecoilState(UserAtom.currentUser);
  const nav = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem(StorageConstant.ACCESS_TOKEN);
    setUser(undefined);
    nav("/sign-in");
    notification.success({
      message: "Đăng xuất thành công",
    });
  };

  return (
    <div className="p-6">
      <Popover
        content={
          <div className="menu-list">
            <div className="menu-item" onClick={handleLogout}>
              <Icon icon="logout" color="#fff" size={24} />
              <span className="mt-1">Đăng xuất</span>
            </div>
          </div>
        }
        trigger="hover"
      >
        <div className="mini-profile p-4 flex items-center gap-4">
          <Avatar size={36} src={avtNone} />
          <div>
            <div className="name">{user?.full_name || "-"}</div>
            <div className="email">{user?.email || "-"}</div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default MiniProfile;
