import React, { Suspense, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Layout from "../components/Layout";
import Loading from "../screens/common/Loading";
import { StorageConstant } from "../services/storage";
import UserApi from "../services/user/user.api";
import UserAtom from "../services/user/user.atom";
import AppRoutes from "./config";

const Router: React.FC = () => {
  const [user, setUser] = useRecoilState(UserAtom.currentUser);
  const nav = useNavigate();

  // Tạm thời em check sơ sơ cái auth vậy để có cái hiển thị nhé chị
  useEffect(() => {
    const token = localStorage.getItem(StorageConstant.ACCESS_TOKEN);

    if (user && user.staff && token) {
      UserApi.getStaffInfo(user.staff.id).then((res) => {
        !res && nav("/sign-in");
      });
    } else {
      localStorage.removeItem(StorageConstant.ACCESS_TOKEN);
      setUser(undefined);
      nav("/sign-in");
    }

    if (user?.permissions?.length === 0) {
      nav("/unauthorization");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Routes>
      {AppRoutes.map((route, index) => {
        if (
          !route.label ||
          (route.label && user?.permissions?.includes(route.label))
        ) {
          return (
            <Route
              key={`${route.path}-${index}`}
              path={route.slug}
              element={
                route.component ? (
                  route.useLayout ? (
                    <Layout>
                      <Suspense fallback={<Loading />}>
                        <route.component />
                      </Suspense>
                    </Layout>
                  ) : (
                    <Suspense fallback={<Loading />}>
                      <route.component />
                    </Suspense>
                  )
                ) : undefined
              }
            >
              {Array.isArray(route.children) &&
                route.children.map((childRoute, childIndex) => (
                  <Route
                    index={childRoute.isIndex}
                    key={`${childRoute.path}-${childIndex}`}
                    path={childRoute.slug}
                    element={
                      <Layout>
                        <Suspense fallback={<Loading />}>
                          <childRoute.component />
                        </Suspense>
                      </Layout>
                    }
                  />
                ))}
            </Route>
          );
        } else {
          return null;
        }
      })}
    </Routes>
  );
};

export default Router;
