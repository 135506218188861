import { Menu, MenuProps } from "antd";
import React, { FC, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Logo from "../../assets/logo.svg";
import AppRoutes from "../../router/config";
import UserAtom from "../../services/user/user.atom";
import Icon from "../Icon";
import MiniProfile from "../MiniProfile";
import "./Sidebar.less";

const Sidebar: FC = () => {
  const nav = useNavigate();
  const location = useLocation();
  const user = useRecoilValue(UserAtom.currentUser);

  const getActiveRoute = useCallback(() => {
    const activeRoutes = location.pathname.split("/").filter((r) => !!r);
    const route = AppRoutes.find((r) => r.slug === activeRoutes[0]);

    if (route?.children) {
      let activeChildRoute = route.children.find(
        (r) => r.slug === activeRoutes.slice(-1)[0]
      );

      if (activeRoutes.length === 1) {
        activeChildRoute = route.children.find((r) => r.isIndex);
      }

      return [route.key, activeChildRoute?.key || ""];
    }

    return [route?.key || ""];
  }, [location]);

  const convertRouteToMenu = (routes: Array<IRoute>, isChildren = false) => {
    return routes
      .map((route) => {
        if (!route.showInSidebar) {
          return null;
        }

        if (
          !isChildren &&
          route.label &&
          !user?.permissions?.includes(route.label)
        ) {
          return null;
        }

        const tempItem: any = {
          key: route.key,
          label: route.label || route.path || "-",
          icon: route.icon || "",
        };

        if (route.children) {
          tempItem.children = convertRouteToMenu(route.children, true);
        }

        if (tempItem.children && tempItem.children.length === 0) {
          delete tempItem.children;
        }

        if (isChildren) {
          return tempItem;
        }

        return {
          ...tempItem,
          icon: <Icon icon={tempItem.icon} color="#F3F4FB" size={22} />,
        };
      })
      .filter((item) => !!item);
  };

  const getRouteChild = (route: IRoute, routeKey: string) => {
    if (Array.isArray(route.children)) {
      const child = route.children.find((cr) => cr.key === routeKey);

      if (child?.isIndex) {
        return route;
      }

      return child;
    }

    return route;
  };

  const onClick: MenuProps["onClick"] = (e) => {
    const itemPositions = [...e.keyPath].reverse();

    const routeArr: Array<IRoute> = [];
    itemPositions.forEach((k, index) => {
      if (index === 0) {
        routeArr.push(AppRoutes.find((r) => r.key === k) || ({} as IRoute));
      } else {
        routeArr.push(getRouteChild(routeArr[index - 1], k) || ({} as IRoute));
      }
    });

    nav(`/${routeArr.slice(-1)[0].path || "#"}`);
  };

  return (
    <div className="flex flex-col sidebar">
      <div className="px-4 py-6">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="flex-1 overflow-x-hidden overflow-y-auto">
        <Menu
          className="sidebar-list"
          onClick={onClick}
          mode="inline"
          items={convertRouteToMenu(AppRoutes)}
          defaultSelectedKeys={getActiveRoute()}
          defaultOpenKeys={getActiveRoute()}
        />
      </div>
      <MiniProfile />
    </div>
  );
};

export default Sidebar;
