import { atom } from "recoil";
import { IUser } from "./types";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const currentUser = atom<IUser | undefined>({
  key: "currentUser",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

const UserAtom = {
  currentUser,
};

export default UserAtom;
