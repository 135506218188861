import { setRecoil } from "recoil-nexus";
import Api from "../api";
import { StorageConstant } from "../storage";
import { GetUserInfoRes } from "./types";
import UserAtom from "./user.atom";

const getStaffInfo = async (idStaff: string) => {
  try {
    const res = await Api<GetUserInfoRes>({
      url: "/v1/staffs/" + idStaff,
      method: "GET",
    });

    if (res.statusCode === 200) {
      return res.data;
    } else {
      localStorage.removeItem(StorageConstant.ACCESS_TOKEN);
      setRecoil(UserAtom.currentUser, undefined);
      return undefined;
    }
  } catch (error) {
    localStorage.removeItem(StorageConstant.ACCESS_TOKEN);
    setRecoil(UserAtom.currentUser, undefined);
    return undefined;
  }
};

const UserApi = {
  getStaffInfo,
};

export default UserApi;
