import React, { FC } from "react";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import "./App.css";
import Router from "./router";

const App: FC = () => {
  return (
    <RecoilRoot>
      <RecoilNexus />
      <Router />
    </RecoilRoot>
  );
};

export default App;
