import React from "react";
import { v4 } from "uuid";

const AppRoutes: Array<IRoute> = [
  {
    key: v4(),
    slug: "sign-in",
    component: React.lazy(() => import("../screens/auth/SignIn")),
    authRequire: false,
    useLayout: false,
    showInSidebar: false,
  },
  {
    key: v4(),
    slug: "forgot-password",
    component: React.lazy(() => import("../screens/auth/ForgotPassword")),
    authRequire: false,
    useLayout: false,
  },
  {
    key: v4(),
    slug: "reset-password",
    component: React.lazy(() => import("../screens/auth/ResetPassword")),
    authRequire: false,
    useLayout: false,
  },
  {
    key: v4(),
    slug: "unauthorization",
    component: React.lazy(() => import("../screens/auth/UnAuthorization")),
    authRequire: true,
    useLayout: false,
  },
  {
    key: v4(),
    slug: "*",
    component: React.lazy(() => import("../screens/common/NotFound")),
    authRequire: false,
    useLayout: false,
  },
  {
    key: v4(),
    slug: "system-management",
    path: "system-management",
    authRequire: true,
    useLayout: true,
    label: "Quản lý hệ thống",
    showInSidebar: true,
    icon: "system",
    children: [
      {
        key: v4(),
        label: "Tài khoản",
        component: React.lazy(() => import("../screens/system/SystemAccount")),
        isIndex: true,
        authRequire: true,
        useLayout: true,
        showInSidebar: true,
      },
      {
        key: v4(),
        label: "Chức vụ",
        slug: "roles",
        path: "system-management/roles",
        component: React.lazy(() => import("../screens/system/SystemRoles")),
        authRequire: true,
        useLayout: true,
        showInSidebar: true,
      },
    ],
  },
  {
    key: v4(),
    slug: "agent-management",
    path: "agent-management",
    authRequire: true,
    useLayout: true,
    label: "Quản lý đối tác",
    showInSidebar: true,
    icon: "roof",
    children: [
      {
        key: v4(),
        component: React.lazy(() => import("../screens/agent/AgentList")),
        authRequire: true,
        useLayout: true,
        isIndex: true,
        label: "Danh sách đối tác",
        showInSidebar: true,
      },
      {
        key: v4(),
        label: "Danh mục đối tác",
        slug: "categories",
        path: "agent-management/categories",
        component: React.lazy(() => import("../screens/agent/AgentCategories")),
        authRequire: true,
        useLayout: true,
        showInSidebar: true,
      },
      {
        key: v4(),
        slug: ":id",
        component: React.lazy(() => import("../screens/agent/AgentDetails")),
        authRequire: true,
        useLayout: true,
      },
    ],
  },
  {
    key: v4(),
    slug: "order-management",
    path: "order-management",
    authRequire: true,
    useLayout: true,
    label: "Quản lý đơn hàng",
    showInSidebar: true,
    icon: "cart",
    children: [
      {
        key: v4(),
        label: "Đơn hàng sản phẩm",
        component: React.lazy(() => import("../screens/order/OrderList")),
        authRequire: true,
        useLayout: true,
        isIndex: true,
        showInSidebar: true,
      },
      {
        key: v4(),
        slug: ":id",
        component: React.lazy(
          () => import("../screens/order/OrderList/OrderDetails")
        ),
        authRequire: true,
        useLayout: true,
      },
      {
        key: v4(),
        label: "Đơn hàng dịch vụ",
        slug: "booking",
        path: "order-management/booking",
        component: React.lazy(() => import("../screens/order/BookingList")),
        authRequire: true,
        useLayout: true,
        showInSidebar: true,
      },
      {
        key: v4(),
        slug: "booking/:id",
        component: React.lazy(
          () => import("../screens/order/BookingList/BookingDetails")
        ),
        authRequire: true,
        useLayout: true,
      },
    ],
  },
  {
    key: v4(),
    slug: "service-category-management",
    path: "service-category-management",
    authRequire: true,
    useLayout: true,
    component: React.lazy(() => import("../screens/service/ServiceCategories")),
    label: "Quản lý danh mục dịch vụ",
    showInSidebar: true,
    icon: "roof",

    // children: [
    //   {
    //     key: v4(),
    //     label: "Danh mục",
    //     component: React.lazy(
    //       () => import("../screens/service/ServiceCategories")
    //     ),
    //     isIndex: true,
    //     authRequire: true,
    //     useLayout: true,
    //     showInSidebar: true,
    //   },
    //   {
    //     key: v4(),
    //     label: "Danh sách",
    //     slug: "list",
    //     path: "services/list",
    //     component: React.lazy(() => import("../screens/service/BookingList")),
    //     authRequire: true,
    //     useLayout: true,
    //     showInSidebar: true,
    //   },
    //   {
    //     key: v4(),
    //     slug: "list/:id",
    //     component: React.lazy(
    //       () => import("../screens/service/BookingDetails")
    //     ),
    //     authRequire: true,
    //     useLayout: true,
    //   },
    // ],
  },
  {
    key: v4(),
    slug: "content-management",
    path: "content-management",
    authRequire: true,
    useLayout: true,
    label: "Quản lý nội dung",
    showInSidebar: true,
    component: React.lazy(
      () => import("../screens/content-management/ContentManagement")
    ),
    icon: "flow",
  },
  {
    key: v4(),
    slug: "report",
    path: "report",
    authRequire: true,
    useLayout: true,
    label: "Báo cáo",
    showInSidebar: true,
    component: React.lazy(() => import("../screens/report/ReportPage")),
    icon: "chart",
  },
  {
    key: v4(),
    slug: "user-management",
    path: "user-management",
    authRequire: true,
    useLayout: true,
    label: "Quản lý người dùng",
    showInSidebar: true,
    icon: "user-tag",
    children: [
      {
        key: v4(),
        component: React.lazy(
          () => import("../screens/user-management/UserList")
        ),
        authRequire: true,
        useLayout: true,
        isIndex: true,
      },
      {
        key: v4(),
        slug: ":id",
        component: React.lazy(
          () => import("../screens/user-management/UserDetail")
        ),
        authRequire: true,
        useLayout: true,
      },
    ],
  },
  {
    key: v4(),
    slug: "sms-notification",
    path: "sms-notification",
    authRequire: true,
    useLayout: true,
    label: "SMS và thông báo",
    showInSidebar: true,
    icon: "notification",
    component: React.lazy(
      () => import("../screens/sms-notification/SmsNotificationList")
    ),
  },
  {
    key: v4(),
    slug: "promotion-management",
    path: "promotion-management",
    authRequire: true,
    useLayout: true,
    label: "Quản lý khuyến mãi",
    showInSidebar: true,
    icon: "sale",
    component: React.lazy(() => import("../screens/promotion/PromotionList")),
  },
  {
    key: v4(),
    slug: "interface-management",
    path: "interface-management",
    authRequire: true,
    useLayout: true,
    label: "Quản lý giao diện App",
    showInSidebar: true,
    icon: "equal",
    children: [
      {
        key: v4(),
        component: React.lazy(
          () => import("../screens/interface-management/Content/Content")
        ),
        authRequire: true,
        useLayout: true,
        isIndex: true,
        label: "Nội dung trang chủ",
        showInSidebar: true,
      },
      {
        key: v4(),
        label: "Popup quảng cáo",
        slug: "popup",
        path: "interface-management/popup",
        component: React.lazy(
          () => import("../screens/interface-management/Popup/Popup")
        ),
        authRequire: true,
        useLayout: true,
        showInSidebar: true,
      },
      {
        key: v4(),
        label: "Top App Banner",
        slug: "banner",
        path: "interface-management/banner",
        component: React.lazy(
          () => import("../screens/interface-management/Banner/Banner")
        ),
        authRequire: true,
        useLayout: true,
        showInSidebar: true,
      },
    ],
  },
  {
    key: v4(),
    slug: "news-management",
    path: "news-management",
    authRequire: true,
    useLayout: true,
    label: "Quản lý tin tức",
    showInSidebar: true,
    icon: "news",
    children: [
      {
        key: v4(),
        label: "Tin tức",
        component: React.lazy(
          () => import("../screens/news-management/NewsList")
        ),
        isIndex: true,
        authRequire: true,
        useLayout: true,
        showInSidebar: true,
      },
      {
        key: v4(),
        label: "Bài đăng",
        slug: "post",
        path: "news-management/post",
        component: React.lazy(
          () => import("../screens/news-management/PostList")
        ),
        authRequire: true,
        useLayout: true,
        showInSidebar: true,
      },
    ],
  },
  {
    key: v4(),
    slug: "config",
    path: "config",
    authRequire: true,
    useLayout: true,
    label: "Cấu hình",
    showInSidebar: true,
    icon: "dots",
    children: [
      {
        key: v4(),
        label: "Quản lý cấu hình",
        component: React.lazy(() => import("../screens/config/Config")),
        isIndex: true,
        authRequire: true,
        useLayout: true,
        showInSidebar: true,
      },
      {
        key: v4(),
        label: "Bộ lọc từ khoá",
        slug: "keyword",
        path: "config/keyword",
        component: React.lazy(() => import("../screens/config/Keyword")),
        authRequire: true,
        useLayout: true,
        showInSidebar: true,
      },
      {
        key: v4(),
        label: "Đề xuất từ khoá",
        slug: "recomended-keyword",
        path: "config/recomended-keyword",
        component: React.lazy(() => import("../screens/config/RecomendedKeyword")),
        authRequire: true,
        useLayout: true,
        showInSidebar: true,
      },
    ],
  },
];

export default AppRoutes;
